$base-color: var(--c-primary);
$done-dark: #6ae5d3;
$OneHundredPercent: 100%;

.copy-container {
  position: relative;
  font-weight: 600;
  padding-right: calc(2em + 6px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  display: inline-flex;
  width: auto;
  max-width: $OneHundredPercent;
  white-space: break-spaces;
  &.low-space
  {
    padding-right: calc(1em + 6px);
  }
  &.block-container {
    display: block;
  }
  &:hover {
    .icon-container {
      .copy-icon {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .icon-container {
    position: absolute;
    right: 0;
    top: 50%;
    .mdi:before {
      line-height: $OneHundredPercent;
      font-size: 16px;
      cursor: pointer;
      color: $base-color !important;
    }
    .copied {
      .mdi:before {
        line-height: $OneHundredPercent;
        font-size: 16px;
        cursor: pointer;
        color: $done-dark !important;
      }
    }
    line-height: 1;
    transform: translateY(-50%);
    .copy-icon {
      opacity: 0;
      visibility: hidden;
      cursor: pointer;
      margin-right: 0 !important;
      &.copied {
        .mdi:before {
          line-height: $OneHundredPercent;
          font-size: 16px;
          cursor: pointer;
          color: $done-dark !important;
        }
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
// Refactoring Done 2023
