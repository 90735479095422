.ConsentRequestDialog {
  .MethodBanner {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-width: 22rem;
    height: 14rem;
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 4px;
    }

    .MethodBanner_card {
      display: flex;
      height: 135px;
      width: 135px;
      padding: 24px 28px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;

      .mdi:before {
        font-size: 50px;
        color: #3b72d9;
      }

      &.primary {
        cursor: pointer;
        border: 1px solid #eaecf0;
        background: #fff;

        &:hover {
          border: 1px solid #3b72d9;
          transition: border 0.8s, transform 0.2s;
          transform: scale(0.98);
        }
      }

      &.active {
        cursor: pointer;
        border: 1.5px solid #3b72d9;
        background: #fff;
      }

      &.disabled {
        border: 1px solid #eaecf0;
        background: #fcfcfd;
        color: #667085;

        .mdi:before {
          color: #667085;
        }
      }
    }
  }
}
