.oldLayoutClass {
  .KycForm {
    .Form_Table {
      width: 75%;
      table-layout: fixed;
      margin-inline: 48px;
      border-collapse: collapse;
      margin-bottom: 25px;
    }

    .Nested_Table,
    .Dynamic_Table {
      width: 100%;
      table-layout: fixed;

      &.tow-col-table {
        table-layout: auto;
        td:nth-of-type(2) {
          width: 65%;
        }
      }
    }

    th,
    td {
      border: 1px solid #333;
    }
    .required_cell_value {
      color: #e63946;
    }

    td {
      padding: 8px;
      font-size: 12px;
    }

    .Spaced_Table_Header {
      th {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 4px 18px;
        background-color: #363f72;
        color: white;
      }

      .Header_Title {
        display: block;
        font-size: 18px;
      }
      .Header_Sub_Title {
        display: block;
        font-size: 14px;
        font-weight: 300;
        text-decoration: underline;
        font-style: italic;
      }
      .Header_Button {
        display: flex;
        padding: 5px 15px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 9999px;
        border: 1px solid #3b72d9;
        background: #fff;
        color: #3b72d9;
        font-weight: 400;
      }
    }

    .Space_After {
      &::after {
        content: "";
        display: block;
        height: 15px;
        background-color: transparent;
      }
    }
    .Row_Text {
      border: unset;
      padding: 15px 0px;
    }

    .Dynamic_Table {
      th {
        padding: 8px;
        font-size: 12px;
      }
    }

    //Components_Style_Override
    .Cell_Value {
      background-color: rgba(179, 184, 219, 0.25);
      padding: 4px 8px;
      min-height: 26px;

      .autocomplete-wrapper,
      .text-field-wrapper {
        height: 18px;
      }
      .MuiInputBase-root {
        background-color: rgba(226, 230, 253, 0.25) !important;
        border: unset !important;
        min-height: 20px !important;
      }
      .MuiFormControl-root {
        margin: 0px !important;
        padding: 0px !important;
        margin-bottom: 0px !important;
      }

      .checkbox-wrapper {
        &.Mui-checked,
        &.MuiCheckbox-indeterminate {
          .mdi {
            background-color: #363f72 !important;
          }
        }
        .mdi {
          height: 20px;
          width: 20px;
          border-radius: 0rem !important;
        }
        .i-unchecked {
          height: 20px;
          width: 20px;
          border: 1px solid #000;
          border-radius: 0rem !important;
          background-color: rgba(154, 160, 202, 0.25);
        }
      }
      &.theme-default {
        .checkbox-wrapper {
          .i-unchecked {
            border-color: #363f72;
          }
          &.Mui-checked,
          &.MuiCheckbox-indeterminate {
            .mdi {
              border-radius: 0rem !important;
              background-color: #363f72;
            }
          }
        }
      }

      .radio-group-wrapper {
        .MuiFormControlLabel-label {
          font-size: 14px;
        }
        &.Mui-checked {
          .radio-icon,
          + .MuiTypography-root {
            color: #363f72;
          }
        }
        .Mui-checked .radio-icon {
          border-color: #363f72 !important;
          color: #363f72 !important;
        }
      }

      .checkbox-groups-wrapper .MuiFormControlLabel-label {
        font-size: 12px !important;
        padding: 4px;
      }
    }
  }
}
