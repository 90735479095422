
    .RateDot {
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 100%;
  
      &.small-dot {
        width: 10px;
        height: 8px;
      }
    }
    .RateDot_red {
      background: #F04438;
    }
    .RateDot_orange {
      background: #F79009;
    }
    .RateDot_green {
      background: #17B26A;
    }
  