.Payment-Details-phase{

    .payment-type-chip {
        display: flex;
        position: absolute;
        right: 0;
        top: 8px;
        padding: 12px;
        align-items: flex-start;
        gap: 16px;
        border-radius: 12px;
        border: 1px solid #EAECF0;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    
    .file-icon-wrapper {
        .file-icon {
            width: 24px;
        }
        .file-title{
            font-size: 8px;
        }
    }
    .btns.theme-transparent .MuiButton-label{
        color: #7F6944 !important;
    }
}