.leave-view-wrapper {
  border-radius: 1rem;
  padding: 0.5rem 0.5rem 1rem;
  border: 1px solid #e6e6e6;
  width: 100%;
  margin-top: 18px;
  .header-leaves {
    display: flex;
    justify-content: space-between;
    .title {
      padding: 0 0.5rem;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 1rem;
    }
  }
}
.actions-btns {
  margin-top: 3%;
  display: flex;
  flex-direction: row-reverse;
}
