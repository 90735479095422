.Dialog_SidePopupActions {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: absolute;
  left: 25px;
  animation: slide-right .5s ease-in-out;

  .SidePopupActions_Button {
    padding: 4px;
    border-radius: 50%;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
    margin-block: 2px;

    .mdi {
      margin-top: 1px;
      display: block;
    }

    &:hover {
      transition: all .5s;
      transform: scale(1.08);
    }

    &.primary {
      border: 1.5px solid #e0e6ec;

      .mdi:before {
        color: #7f6944;
      }
    }

    &.active {
      border: 1px solid transparent;
      background: #7f6944;

      .mdi:before {
        color: #FFF;
      }
    }
  }
}

.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-left: 0;
    left: 0;
  }

  100% {
    -webkit-left: 25px;
    left: 25px;
  }
}

@keyframes slide-right {
  0% {
    -webkit-left: 0;
    left: 0;
  }

  100% {
    -webkit-left: 25px;
    left: 25px;
  }
}