// A4 height size : 3508px
// A4 width size : 2480px
// zoom ratio : 0.142

.ProposalPreview {
    width: 352.16px; // 2480px * 0.142 = 352.16px

    .ProposalPreview_iframe {
        //border: 1px solid #eaecf0;
        border-radius: 5.5rem;
        background: #fff;
        margin: 2%;
        margin-right: 64px;
        width: 100%;
        min-height: 3508px;
        zoom: 0.142;
        border: none;
    }

    @supports not (zoom: 0.142) {
        transform: scale(0.142);
        transform-origin: top left;
        width: calc(100% / 0.142);
    }
}

