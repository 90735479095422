.filterSalesTransactions {
  display: flex !important;
}
.containerTwoAutoComplete {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1280px) {
  .filterSalesTransactions {
    display: grid !important;
  }
}

