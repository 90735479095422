.sellerInfo {
  margin-top: 0.5rem;
  padding: 1rem;
  margin-right: 4rem;
  border-radius: 0.5rem;
  .card {
  
    .headtitle{
      font-weight: 600;
    }
    .titleSection {
      line-height: 1.42857143;
      color: var(--c-primary);
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      display: block;
    }

    .sellerInfoContant {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      .sectionItem {
        width: fit-content;
        display: flex;
        margin: 0.5rem;
        font-size: .8rem ;
      }
    }
  }
}
