.more-filters-dialog-container {
  padding: 20px;
}

.filters-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filter-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.cancel-button {
  background: #f5f5f5;
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: #e0e0e0;
}

.show-button {
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: bold;
}

.show-button:disabled {
  background-color: #e0e0e0 !important;
  color: #000000de !important;
}

.sliders-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  border: 1px solid #e0e0e0;
  padding: 16px;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.slider-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}
