.Avatar {
  height: 55px !important;
  width: 55px !important;
  max-width: 55px;
  min-width: 55px;
  background-color: #8e8e93;
  color: #ffffff;
  margin-right: 0.8rem;
  font-size: 1rem;
  border: 3px solid var(--Component-colors-Components-Avatars-avatar-profile-photo-border, #fff);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);

  img {
    object-fit: fill;
  }
}

.Avatar_Text {
  display: flex;
  flex-direction: column;

  &> :first-child {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--colors-text-text-secondary-700, #344054);
  }

  &> :nth-child(2) {
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    color: var(--colors-text-text-tertiary-600, #475467);
  }
}

.Info_Pair {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  &> :first-child {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: var(--colors-text-text-tertiary-600, #475467);
  }

  &> :nth-child(2) {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: var(--colors-text-text-secondary-700, #344054);
  }
}


.Content_Box {
  position: relative;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  height: fit-content;
  min-height: 220px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Colors-Border-border-secondary, #eaecf0);
  background: #fcfcfd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &> :first-child {
    width: 100%;
    border-bottom: 1px solid #EAECF0;
    padding-bottom: 14px;

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
  }
}

.Content_Box:hover .Actions_box button {
  animation-name: ButtonsSwipe;
  display: block;
  animation-duration: 600ms;
  animation-timing-function: ease-out;
}

.Actions_box {
  position: absolute;
  right: 0;
  bottom: 120px;
}

.Actions_box button {
  position: absolute;
  display: none;
  right: 10px;
}

.btn_phone {
  top: 0px;
}

.btn_email {
  top: 34px;
  animation-delay: 50ms;
}

.btn_whatsapp {
  top: 68px;
  animation-delay: 100ms;
}




@keyframes ButtonsSwipe {
  from {
    right: 0px;
  }

  to {
    right: 10px;
  }
}