.custom-dialog-content {
  .inputs-container {
    input[type="radio"] {
      appearance: none;
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      border: 2px solid #84693f;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      position: relative;
      margin: 0px 6px;
    }

    input[type="radio"]:checked::before {
      content: "";
      display: block;
      width: 9px;
      height: 9px;
      background-color: #84693f;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.input-fieldset-legend {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0;
  padding: 0px 4px;
  font-size: 12px;
  line-height: inherit;
  border: 0;
  color: #000000;
}

.input-fieldset {
  border: 1px solid #ededed;
  border-radius: 8px;
  min-height: 38px;
  padding: 10px;
}


.float-right{
  float: right !important;
}

.is-custom-text{
  color: #117bd5;
  align-self: flex-end;
  font-size: 16px;
  margin-top: 4px;
}