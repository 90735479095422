.message-confirm-dialog {

    .dialog-title-wrapper .MuiTypography-root,
    .MuiDialogTitle-root,
    .dialog-content-wrapper,
    .MuiDialogContent-root,
    .MuiDialogActions-root {
        padding: 0 0 !important;
    }

    .dialog-body{
        background: url(../../assets/images/Propx/bg-pattern-decorative-circles.png) no-repeat;

    }
    .dialog-content {
        display: grid;
        grid-template-columns: 48px auto;
    }
    .dialog-icon {
        display: flex;
        width: 48px;
        height: 48px;
        padding: 12px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        
        &.alert{
            background: url('../../assets/images/Propxicon/alert-triangle-red.svg') no-repeat center , #FEE4E2;
        }
        &.warning{
            background: url('../../assets/images/Propxicon/alert-triangle-yellow.svg') no-repeat center , #FEF0C7;
        }
    }

    .allow-select-text {
        user-select: text;
        -webkit-user-select: text;
        -ms-user-select: text;
    }

    .content-body{
        font-size: 12px;
        font-weight: 400;    
    }
}