.Bank-Account-Card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    padding: 24px;
    // gap: var(--spacing-4xl, 32px);
    border-radius: 12px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .text-wrap {
        text-wrap: wrap;
        line-height: 1.25;
      }
}

.dark-loader {
    filter: brightness(.5)
}