.my-tasks-view {
  background-color: #fcfcfd;


  .propx-view-container {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 24px;
    margin: 32px;
    border-radius: 12px;
    background: #fff;
    height: 70vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }


  .relative {
    position: relative;
  }

  .pointer {
    cursor: pointer;
  }

  .w-fit {
    width: fit-content;
  }


  .fz-16 {
    font-size: 16px;
    line-height: 22px;
  }

  .fz-12 {
    font-size: 12px;
    line-height: 20px;
  }

  .fz-10 {
    font-size: 10px;
    line-height: 18px;
  }

  .fz-20 {
    font-size: 20px;
    line-height: 16px;
  }

  .fz-30 {
    font-size: 30px;
    line-height: 16px;
  }

  .radius-xl {
    border-radius: 12px;
  }

  .radius-md {
    border-radius: 6px;
  }

  .radius-full {
    border-radius: 9999px;
  }

  .text-primary {
    color: #101828;
  }

  .text-tertiary {
    color: #475467;
  }

  .text-gray-dark {
    color: #344054;
  }

  .text-brand-secondary {
    color: #635235;
  }

  .word-break {
    word-break: break-all;
  }
  .bg-gray-light {
    background-color: #f9fafb !important;
  }

  .border-gray-secondary {
    border: 1px solid #eaecf0;
  }

  .border-gray-tertiary {
    border: 1px solid #f8f8f8;
  }

  .b-top {
    border-top: 1px solid #eaecf0;
  }


  .d-block {
    display: block;
  }

  .min-width-unset {
    min-width: unset !important;

    .autocomplete-wrapper {
      min-width: unset !important;
    }
  }

  .marketing-buttons {
    border-radius: 8px;
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    align-items: center;
    margin-inline: 4px;
    min-width: 85px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    img {
      width: 20px;
      height: 20px;
    }

    &.solid {
      border: 1px solid #498a8b;
      background: #498a8b;
      color: white;
    }

    &.outlined {
      border: 1px solid #498a8b;
      color: #498a8b;
    }

    &.disabled {
      border: 1px solid #98a2b3 !important;
      color: #98a2b3 !important;
      background: transparent !important;
    }
  }

  &.board-overflow,
  .board-overflow {
    overflow-x: auto;
    min-width: 1164px;
  }

  .kenban-filters {
    min-width: 1164px;
  }
}