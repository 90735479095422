.Reel-Stepper {

    &.vertical {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-height: 65vh;
        overflow-y: auto;
    
        &::-webkit-scrollbar {
            width: 0px;
            height: 0px;
        }
    }
}