.report-style-class {
  width: 1500px;
  height: 1200px;
  @media only screen and (max-width: 600px) {
    width: 750px !important;
    height: 450px !important;
  }
}

.report-style-class-Show {
  width: 1500px;
  height: 65rem;
  margin: 1%;
}

@media only screen and (max-width: 600px) {
  .report-style-class-Show {
    width: 750px !important;
    height: 450px !important;
  }
}

@media only screen and (max-width: 1536px) {
  .report-style-class-Show {
    width: 1700px !important;
    height: 70rem !important;
    margin: 1% !important;
  }
}

@media only screen and (max-width: 1920px) {
  .report-style-class-Show {
    width: 1800px !important;
    height: 80rem !important;
    margin: 1% !important;
  }
}

@media only screen and (max-width: 2560px) {
  .report-style-class-Show {
    width: 2500px !important;
    height: 80rem !important;
    margin: 1% !important;
  }
}

.report-style-class-Show-Dialog {
  width: 1100px;
  height: 740px;
  @media only screen and (max-width: 600px) {
    width: 750px !important;
    height: 450px !important;
  }

  iframe {
    border: none !important;
  }
}

.report-style-class-hide {
  display: none !important;
}
.dashboardMain {
  display: flex;
  justify-content: center;
}
.dashboardMaindate {
  display: flex;
  justify-content: center;
  margin-top: -2%;
}

.dashboardMain-PowerBIEmbed {
  .mainCardDashboards {
    display: flex;
    justify-content: center;
    height: auto;
  }
  .cardDashboard {
    border-radius: 15px;
    box-shadow: 0px 10px 20px 0px #b3b3b3;
    width: 95%;
    display: flex;
    flex-wrap: wrap;
  }
  .cardDashboard2 {
    width: 850px;
  }
  .cardDashboardgold {
    border-radius: 15px;
    box-shadow: 0px 10px 20px 0px #7f6944;
    width: 95%;
  }
  .selecterbi {
    display: flex;
    justify-content: center;
    margin-top: 0.5%;
  }
  .selecterDate {
    display: flex;
    justify-content: center;
    margin-top: 0.5%;
    margin-left: 2%;
  }
  .selecterbi2 {
    display: flex;
    justify-content: center;
    margin-top: 0.5%;
  }
  iframe {
    border: none;
  }

  .mediaDetailBi {
    margin-top: 2%;
    width: 0px;
  }

  .MainFiltersBi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .MainFiltersBi2 {
    flex-wrap: wrap;
    justify-content: center;
  }

  .clearDashboardFilter {
    margin-top: 0.7%;
    display: flex;
    height: 30px;
    width: 127px;
  }
  .viewTypeDashboard {
    margin-top: 0.7%;
    display: flex;
    height: 30px;
  }
  .DivDashboardFilterbtn {
    margin-top: 0.4%;
    display: flex;
  }

  .DashboardFilterbtn {
    display: flex;
    height: 28px;
  }
  .Dashboardbtn {
    min-width: 45px !important;
  }
  .DashboardFilter {
    margin-top: 0.5%;
    display: flex;
    height: 30px;
    justify-content: center;
  }
  .DashboardMenuPage {
    margin-top: 0.6%;
    display: flex;
    height: 30px;
    justify-content: center;
    margin-right: 1%;
  }
  .DashboardFiltertheme {
    margin-top: 0.5%;
    display: flex;
    height: 30px;
    margin-left: 1%;
  }
}

.usernameMain {
  font-size: 16px;
  display: flex;
  justify-content: center;
  margin: 1%;
}

.greeting {
  margin-right: 0.5%;
}

.buttonActivitygroup {
  margin-top: 0.2%;
  border: 1px solid gray;
  padding: 0.2%;
  border-radius: 10px;
  box-shadow: 0px 10px 20px 0px #b3b3b3;
}

.chartActive {
  background-color: #133c89 !important;
  color: white !important;
  border-radius: 5px !important;
  width: 60px;
}
.chartNotActive {
  background-color: #9fa1a3 !important;
  color: white !important;
  border-radius: 5px !important;
  width: 40px;
}

.menuButtonActive {
  color: white !important;
  background-color: #0c111dd4 !important;
}

.menuButtonNotActive {
  color: black !important;
}
.menuActive {
  color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: #0c111dd4 !important;
  min-width: 120px !important;
  height: 2rem;
  margin-right: 1% !important;
  margin-left: 1% !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.menuNotActive {
  color: black !important;
  min-width: 120px !important;
  height: 2rem;
  transition: background-color 0.3s ease, color 0.3s ease;
  border: 1px solid #dedede !important;
}

.menuNotActive:hover {
  background-color: #0c111d54 !important;
  color: #f2f2f7 !important;
}

.buttonActive {
  color: white !important;
  width: 100px;
}
.buttonNotActive {
  background: #9fa1a3 !important;
  color: white !important;
  width: 100px;
}

.bluethemeActive {
  color: white !important;
  border-radius: 5px !important;
  width: 100px;
}
.goldthemeActive {
  background: #7f6944 !important ;
  color: white !important;
  border-radius: 5px !important;
  width: 100px;
}

.themenotActive {
  background: #9fa1a3 !important ;
  color: white !important;
  border-radius: 5px !important;
  width: 80px;
}

.SwitchTheme {
  display: flex;
  justify-content: center;
}
.MenuGroup {
  display: flex;
  justify-content: center;
  margin: 1%;
}

.ThemeIcon {
  display: flex;
  align-items: center;
}

.MultiDashboards {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1536px) {
  .MultiDashboards {
    display: grid;
  }
}

.MultiDashboardsCards {
  height: auto;
}
@media only screen and (max-width: 1536px) {
  .MultiDashboardsCards {
    height: 90rem;
  }
}

.adminbutton {
  padding: 1%;
}

.loadingDiv {
  background-color: #ffffff;
  height: 100%;
  width: 50%;
  position: absolute;
  display: flex;
}

.adminMenu {
  width: 80%;
  padding: 1%;
  margin: 1%;

  border-radius: 10px;
  box-shadow: 0px 10px 20px 0px #b3b3b3;
}

.adminbutton {
  display: flex;
  justify-content: center;
  padding: 0.5%;
}


.cardChart {
  border-radius: 10px;
  box-shadow: 0px 5px 10px -5px #b3b3b3;
  background-color: white;
  cursor: pointer;
  width: 15rem;
}

@media only screen and (max-width: 1536px) {
  .cardChart {
    width: 13rem;
  }
}

.cardChart:hover {
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
  opacity: 1;
  .image {
   
    transition: 0.3s ease-out;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgb(236, 88, 25);
  }

}
.infos {
  display: flex;

  align-items: center;

  justify-content: flex-start;
}

.image {
  height: 3rem;
  width: 3rem;
  justify-content: center;
  /* border-radius: 0.5rem; */
  background-color: #3b72d9;
  /* background: linear-gradient(to bottom right, #7624c2, #b980f0); */
  display: flex;
  color: white;
  border-top-left-radius: 10px;
  align-items: center;
  border-bottom-left-radius: 10px;
}

.info {
  flex: 1 1;
  display: flex;
  justify-content: center;
}

.name {
  font-size: 15px;
  /* line-height: 1.75rem; */
  font-weight: 500;
  color: #0c0c0c;
}
