
.propertyFinder-dialog-wrapper {
    .MuiDialogTitle-root {
    //  border-bottom: 1px solid $c-gray-dark !important;
      padding-left: 2rem;
    }
    .MuiDialog-paper {
      min-width: 30rem;
    }
    .MuiDialogContent-root {
      padding: 1rem;
      min-width: 30rem;
    }
    .dialog-content-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .dialog-content-item {
        width: 50%;
        margin-bottom: 0.5rem;
 

        @media only screen and (max-width: 768px) {
        
            width: 100%;
          
      }

        padding: 0 0.5rem;
      }
      .title-wrapper {
        display: flex;
        margin-bottom: 0.5rem;
        padding: 0 0.5rem;
        .title-text {
          font-weight:400;
        }
        .Requierd-Color::after {
          content: ' *';
          color: red;
        }
      }
  
    }
  }
  