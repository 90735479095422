.autocomplete-legend-wrapper {
    width: fit-content;
    margin: 0;
    padding: 0px 4px;
    font-size: 12px;
    line-height: inherit;
    border: 0;
    color: #000000;
}


.fieldset-wrapper-for-dialog {
    border: 1px solid #ededed;
    border-radius: 8px;
    min-height: 38px;
    padding: 10px;
}

.email-phone-input-holder{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 0 5px 0 5px;
}