.status-breadcrumb {
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  padding: 1rem 0;
  position: relative;
  margin-left: 2rem;

  .breadcrumb-contact {
    margin: 3px;
    position: relative;
    padding: 4px 6px;
    cursor: pointer;
    color: #344054;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      height: 50%;
      width: 100%;
      background: #fff;
      z-index: -1;
    }

    &::before {
      top: 0;
      transform: skew(20deg);
      border: #e0e0e0 solid 1px;
      border-bottom: none;
    }

    &::after {
      bottom: 0;
      transform: skew(-20deg);
      border: #e0e0e0 solid 1px;
      border-top: none;
    }
    &:hover {
      color: #fff;
      &::before,
      &::after {
        background: #2153b1;
      }
    }

    .breadcrumb-value {
      display: inline-block;
      position: relative;
      min-height: 44px;
      padding: 8px 12px;
      .breadcrumb-text {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
    .breadcrumb-disabled {
      pointer-events: none;
      opacity: 0.5;
      &:hover {
        &::before,
        &::after {
          background: #fff;
        }
      }
    }
  }
  .breadcrumb-contact-disabled {
    cursor: not-allowed;
    &:hover {
      color: #344054;
      &::before,
      &::after {
        background: #fff;
      }
    }
  }
}

.marketing-stages-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 10px;
  .upload-image-btn-wrapper {
    width: 268px;
    .MuiButton-label {
      gap: 10px;
    }
  }
  .upload-image-btn {
    display: flex;
    padding: 3px 35px !important;
    justify-content: center;
    align-items: center;
  }
  .upload-image-text {
    color: #000;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.publish-dialog-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  .publish-field {
    width: 100%;
    min-height: 70px;
  }
  .publish-filed-label {
    color: #000;
    font-size: 15px;
    font-weight: 600;
  }
  .publish-filed-text {
    color: #000;
    font-size: 15px;
    font-weight: 400;
  }
  .agent-avatar {
    height: 55px !important;
    width: 55px !important;
    max-width: 55px;
    min-width: 55px;
    background-color: #8e8e93;
    color: #ffffff;
    margin-right: 0.8rem;
    font-size: 1rem;
    border: 3px solid #fff;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);

    img {
      object-fit: fill;
    }
  }

  .agent-avatar-text {
    display: flex;
    flex-direction: column;

    & > :first-child {
      color: #344054;
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }

    & > :nth-child(2) {
      color: #475467;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .publish-img-wrapper {
    display: flex;
    padding: 0px 10px;
    align-items: center;
    gap: 12px;
    width: 100%;
    .publish-img {
      width: 170px;
      border-radius: 10px;
    }
    .image-width {
      width: 100%;
    }
  }
  .publish-action-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .publish-action-left-side {
    display: flex;
    gap: 16px;
  }
  .edit-btn {
    border-radius: 17px;
    border: 1px solid #3b72d9;
    padding: 3px 35px !important;
    height: 32px !important;
  }
  .confirm-btn {
    padding: 3px 35px !important;
    height: 32px !important;
  }
}
