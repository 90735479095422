.propx-view{

  &.propx-bg{
    background-color: #fcfcfd;
    min-height: 92vh;
  }
  .relative {
    position: relative;
  }
  .fz-30 {
    font-size: 30px;
  }

  .fz-20 {
    font-size: 20px;
  }

  .fz-18 {
    font-size: 18px;
  }
  .fz-16 {
    font-size: 16px;
  }
  .fz-14 {
    font-size: 14px;
  }
  .fz-12 {
    font-size: 12px;
  }
  .fz-10 {
    font-size: 10px;
  }

  .box {
    display: flex;
    align-items: flex-start;
    padding: 8px 12px;
    border-radius: 12px;
    border: 1px solid #EAECF0;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  
    .i-24 {
      width: 24px;
      height: 24px;
      background-size: 24px 24px;
    }
  
    .i-20 {
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
    }
  .pointer{
    cursor: pointer;
  }
  .opacity-8{
    opacity: 0.8
  }
  .opacity-3{
    opacity: 0.3
  }

  .c-propx {
    color: #7F6944;
  }


  .b-bottom {
    border-bottom: 1px solid #eaecf0;
  }


  .text-primary {
    color: #101828;
  }

  .max-height {
    max-height: 30px;
  }

  .text-wrap {
    text-wrap: wrap;
    line-height: 1.25;
  }
  


  .propx-action-btn {
    &.outlined {
      background-color: transparent !important;

    }

    &.danger{
      border: 1px solid #FDA29B !important;
      color: #B42318 !important;
    }

    &.gold{
      border: 1px solid #7F6944 !important;
      color: #7F6944 !important;
    }

    &.gold-text {
      color: #7F6944 !important;
    }
  }

  .propx-view-container {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 24px;
    margin: 32px;
    border-radius: 12px;
    background: #fff;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
    &.sm {
      height: 50vh;
    }

    &.md {
      height: 70vh;
    }

    .table-filter-operators-btn{
      background-color: #7f6944 !important;
    }

  }
  .checkbox-groups-wrapper .i-unchecked {
    height: 16px;
    width: 16px;
    border: 1px solid #d0d5dd !important;
  }

  .customDatePicker {
    .description-text,
    .mdi-chevron-down,
    .c-secondary {
      color: #7f6944 !important;
    }
  }
}

.add-task-dialog {
  .fw-simi-bold {
    font-weight: 600;
  }
  .form-builder-wrapper .stepper-wrapper{
    display: none;
  }
  
  .form-builder-item-wrapper{
    &.MuiGrid-grid-xl-4{
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .font-b {
    font-weight: 600;
  }

  .reminder-controls {
    width: fit-content;
    display: flex;
    margin-right: 30px;
  }

  .pointer {
    cursor: pointer;
  }

  .dialog-title-icon,
  &.dialog-title-icon {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .dialog-content,
  &.dialog-content {
    max-height: 50vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }

  .xCloseIcon img {
    width: 24px;
    height: 24px;
  }

  &.task-details {

    .details-dialog-wrap{
      max-height: 70vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
    }

    .container-frame {
      border-radius: 12px;
      border: 1px solid #eaecf0;
      background: #fff;
      padding: 15px;
    }

    .details-container {
      display: grid;
      grid-template-columns: repeat(3, 160px);
      column-gap: 1rem;

      .details-title {
        color: #475467;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 0px;
      }

      .details-value {
        color: #344054;
        font-size: 14px;
        font-weight: 600;
      }

      .details-value,
      .details-title {
        max-width: 140px;
        overflow-wrap: break-word;
      }
    }
  }
}
.pointer {
  cursor: pointer;
}

.info-capsule{
  padding: 2px 10px;
  border-radius: 9999px;
  &.gray {
    border: 1px solid #EAECF0;
    background:  #F9FAFB;
  }
  &.gold {
    color: #7F6944;
    border: 1px solid #CABFAC;
    background: #F4F1ED;
  }
  &.navy {
    color: #363F72;
    border: 1px solid #D5D9EB;
    background: #F8F9FC;
  }
  &.red {
    color: #B42318;
    border: 1px solid #FECDCA;
    background: #FEF3F2;
  }
  &.green {
    color: #067647;
    border: 1px solid #ABEFC6;
    background: #ECFDF3;
  }
}