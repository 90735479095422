.Proposal_selection__Dialog {
    display: flex;
    flex-direction: column;

    .Proposal_preview__Container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 310px;
        gap: 20px;
        overflow: hidden;
    }

    .MuiDialogContent-root {
        padding: 0px !important;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .MuiDialogActions-root {
        padding: 24px !important;
        border-block: 1px solid #eaecf0;

        button {
            margin-block: 0px !important;
        }
    }

    //switch
    .switch-control-wrapper.thick-theme .MuiSwitch-root {
        padding-top: 0px;
    }

    .switch-control-wrapper.thick-theme .MuiSwitch-switchBase {
        bottom: 14px;
    }
    .switch-control-wrapper.thick-theme .label-wrapper {
        margin-top: 0;
    }

    .switch-control-wrapper.thick-theme {
        align-items: start !important;
    }

    .Proposal_preview__Card {
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        border: 1px solid #EAECF0;
        background: #FFF;
        cursor: pointer;
        transition: 0.15s all ease-in;
    }

    .Proposal_preview__Header {
        display: flex;
        width: 100%;
        padding: 15px 24px;
        justify-content: space-between;
        align-items: center;
        border-radius: 11px 11px 0px 0px;
        background: #F2F4F7;

        p {
            margin: 0;
            color: #2D3E50;
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
        }
    }

    .Proposal_preview__Body {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: center;
    }

    .Proposal_preview__Box {
        position: relative;
        height: 210px;
        width: 100%;
        border-radius: 8px;
        background-color: #E5E5EA;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border: 1px solid #E5E5EA;
    }

    .Proposal_selection_Content {
        height: 365px;
        padding-inline: 24px;
        padding-top: 20px;
    }

    .Propsal_mode{
        border-block: 1px solid #EAECF0;
        padding: 18px 24px;
    }

    .Proposal_preview__Card.active {
        border: 2px solid #7F6944;

        .Proposal_preview__Header {
            background: #F4F1ED;
        }
    }

    .Proposal_preview__Card:not(.active):hover {
        border: 2px solid #E5E5EA;
    }

    /* shine effect*/
    .Proposal_preview__Card:hover .Proposal_preview__Box:after {
        content: '';
        top: 0;
        transform: translateX(100%);
        width: 0px;
        height: 220px;
        position: absolute;
        z-index: -1;
        animation: shine-slide 1s ease-in-out;
        background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.5)), color-stop(99%, rgba(128, 186, 232, 0)), color-stop(100%, rgba(125, 185, 232, 0)));
        /* Chrome,Safari4+ */
    }



    .Proposal_preview__Box.premium_light {
        background-image: url("../../assets/Images/premium_light_propsal_cover.svg");
    }

    .Proposal_preview__Box.premium_dark {
        background-image: url("../../assets/Images/premium_dark_propsal_cover.svg");
    }

    .Proposal_preview__Box.classic_light {
        background-image: url("../../assets/Images/classic_propsal_cover.svg");
    }



    /* animation */
    @keyframes shine-slide {
        0% {
            transform: translateX(-100%);
            z-index: 1;
            width: 200px;
        }

        100% {
            transform: translateX(100%);
            z-index: 1;
            width: 200px;
        }
    }

    .dialog_field{
        border-top: 1px solid #eaecf0;
    }
}

.Proposal_selection__Action_btn{
    position: relative;
    z-index: 2;
    padding-block: 1.5px !important;
    border: 1px solid #2153b1 !important;
}

.MuiDialogContent-root:has(.Proposal_selection__Dialog) {
    padding: 0px;
    border-block: 1px solid #EAECF0;

    .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track {
        background-color: #7e7e7e;
    }
}

.MuiPaper-root:has(.Proposal_selection__Dialog) {
    &.MuiDialog-paperWidthSm {
        max-width: 730px !important;
    }
}