.File-Info-Card {
    position: relative;
    display: flex;
    padding: 16px;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid #EAECF0;
    background: #FFF;

    .file-actions {
        display: flex;
        align-items: flex-start;
        gap: 12px;
    }

    .doc-image{
        max-width: 40px;
        margin-right: 8px;
    }

    .mdi-close{
       position: absolute;
       right: 14px;
       top: 14px;
       &::before{
        font-size: 16px;
       }
    }
}