.ShareTemplateDialog {
 
  .fz-14{
    font-size: 14px;
  }

  .MethodBanner {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 22rem;
    height: 14rem;
    overflow-x: auto;

    &::-webkit-scrollbar {
        width: 0px;
        height: 4px;
    }

    .MethodBanner_card {
        display: flex;
        height: 135px;
        width: 135px;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        .mdi:before {
          font-size: 50px;
          color: #3b72d9;
      }

        &.primary{
            cursor: pointer;
            border: 1px solid #EAECF0;
            background: #FFF;

            &:hover {
                border: 1px solid #3b72d9;
                transition: border .8s, transform .2s;
                transform: scale(.98);
            }
        }

        &.active{
            cursor: pointer;
            border: 1.5px solid #3b72d9;
            background: #FFF;
        }
    }
  }
}
