.ActivityFilesUploader-wrapper {

  .form-item {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .form-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-height: 42rem;
  }
  .part-add {
    // margin: 0rem 0.1rem 0.4rem 0.1rem;
    .MuiFab-sizeSmall {
      width: 28px;
      height: 28px;
    }

    .MuiFab-root {
      min-height: 21px;
    }
  }
  .part-1 {
    min-width: 150px;
  }
  .part-2 {
    margin: 0rem 0rem 0.4rem 0rem;
  }
  .Uploader {
    max-width: 40rem;
    margin: auto;
    }
    
    .dropzone-wrapper .uploader-chip {
      display: none;
    }
    
    .uploaded-files-container {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      max-width: 85%;
      margin: 20px auto;
    }
    
    .file-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-height: 55px;
      width: 200px;
      margin: 5px;
      padding: 8px 6px;
      border: 1px #e5e3e3 solid;
      border-radius: 5px;
      box-shadow: 0 0 4px rgb(194, 194, 194);
    
      span {
        white-space: nowrap;
        max-width: 115px;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;

        &:hover{
          font-weight: 600;
        }
      }
    
      .card-body {
        display: flex;
        align-items: center;
      }
    }

   .card-image {
      position: relative;
  
      img {
        max-width: 40px;
        margin-right: 8px;
      }
  
      .mdi:before {
        color: #636363;
        cursor: pointer;
      }
      
      &:hover img {
        filter: blur(4px);
      }
      
      &:hover .mdi-eye {
        display: block;
      }
  
    }

   .card-image .mdi-eye {
      display: none;
      position: absolute;
      z-index: 10;
      left: 10px;
      bottom: 10px;
    }

    .delete-btn .mdi:before {
      font-size: 15px;
      color: gray;
      cursor: pointer;
    }

    .notification-btn {
      margin: 0 40%;
    }
}
