.leads-profile-wrapper {
  padding: 0 0.5rem;
  .childs-wrapper {
    border-radius: 1rem;
    padding: 0.5rem 0.5rem 1rem;
    // border: 1px solid $c-gray-secondary;
    &.b-0 {
      border-width: 0;
    }
    .title-section {
      padding: 0 0.5rem;
      // font-weight: $fw-simi-bold;
      // font-size: $fz-16px;
      margin-bottom: 1rem;
    }
  }
  .form-item {
    // @include d-inline-flex;
    width: 25%;

    .helper_rangField
       {
         display: flex;
         justify-content: space-between;
         width: 100%;
         margin: .2rem  .5rem ; 
         .InformationIcon { 
          color: var(--c-primary) ;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
         } 
       }
    .information-open-file-1 {

      display: flex;

      //flex: 1 1 100%;

      justify-content: flex-end;

      align-items: center;

      margin: .3rem;

      color: var(--c-primary) ;

    }
     
    .helperRangInformation 
    {
       display:  flex ;
       justify-content:  space-between ;
      //  color: $c-red;
       width: 100% ;
       .iconInfo {
        margin-left: .2rem ;
        margin-right: .2rem ;
       }

    }



    .information-open-file-2 {

      display: flex;

     // flex: 1 1 100%;

      justify-content: center;

      align-items: center;

      margin: .3rem;

      color: var(--c-primary) ;

    }
    // @include laptops-xl-max {
    //   width: 33.3333%;
    // }
    // @include tablets-max {
    //   width: 50%;
    // }
    // @include phones-s-max {
    //   width: 100%;
    // }
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
  .tabs-content-wrapper {
    padding: 1rem 0.5rem 0;
    align-items: flex-start;
  }
  .filter-sections-wrapper {
    // @include d-flex-v-center;
    flex-wrap: wrap;
    .filter-section-item {
      // @include d-inline-flex-v-center;
      margin-bottom: 0.5rem;
    }
  }
}

  .Select_Button_Type {
    position: absolute;
    z-index: 2;

    .select-wrapper.MuiFormControl-root .selects {
      display: unset !important;
      color: transparent !important;
    }
  
    .select-wrapper.MuiFormControl-root {
      background: transparent !important;
      min-height: unset;
    }
  
    .select-wrapper.MuiFormControl-root .selects .MuiSelect-select {
      width: 80px !important;
      height: 30px;
      border: unset !important;
      background: transparent !important;
    }

    .mdi-chevron-down {
      display: none !important;
    }
}

