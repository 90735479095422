
.CampaignAttachedFilesView{

    .file-icon-wrapper {
        .file-icon{
            width: 32px;
        }
        .file-size {
            color: #475467;
            font-size: 10px;
        }
    }
}
