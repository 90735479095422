.req-history-details {
  h5 {
    text-align: center;
  }
}

  .req-history-card-container {
    overflow-y: auto;
    max-height: 100vh;
    padding-bottom: 16rem;
    padding-inline: 1rem;

    &::-webkit-scrollbar {
      width: 0 !important;

    }

    .req-avatar {
      height: 32px;
      width: 32px;
      background-position: center;
      background-size: cover;
      border-radius: 50%;
      border: 1px solid #8d8d8d;
    }
  }

  .req-history-card {
    display: flex;
    justify-content: space-between;

    &:before {
      content: "";
      width: 2px;
      background-color: #EAECF0;
      left: 34px;
      position: relative;
      z-index: -1;
      margin-bottom: 5px;
      margin-top: 40px;
    }

    .req-history-content {
      width: 75%;
      padding-bottom: 16px;
    }

    .top-label {
      :first-child {
        font-size: 12px;
        font-weight: 600;
        margin-right: 8px;
      }

      :last-child {
        font-size: 10px;
      }

    }

    .card-capsule {
        display: flex;
        width: fit-content;
        padding: 0.15rem 0.5rem;
        border-radius: 1.75rem;
        background: #e7f5ff;
        font-size: 0.7rem;
        font-weight: 600;
        color: #026AA2;
    }

    .bottom-label {
      font-size: 12px;

      .historyMessage {
        color: #026AA2;
        font-weight: 600;
        font-size: 11px;
      }
    }
    .status-note{
      border: 1px solid #e3e3e3;
      border-radius: 0px 12px 12px;
      font-size: 12px;
      background: #f2f2f7;
      font-weight: 600;
      p {
        margin: 0;
        font-size: 10px;
      }
      
    }
  }
