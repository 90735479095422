.grouped-avatar-component {
  position: relative;

  ul {
    z-index: 100;
  }
  .pointer {
    cursor: pointer;
  }
  .active-avatar{
    outline: 2px solid var(--c-primary) !important;
  }
  .active-listItem{
    background: #f0f8ff;
    color: var(--c-primary);
  }

  img {
    object-fit: fill;
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}
