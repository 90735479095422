.cheque-body {
    width: 540px;
    height: 152px;
    background: rgba(254, 201, 37, 0.30);
    padding: 18px 16px;
    border-top: solid 3.25px #FEC925;
}

.cheque-content {
    display: flex;
    gap: 22px
}

.cheque-content .cheque-field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    border-bottom: solid 1px black;
}

.cheque-field .en-label,
.cheque-field .ar-label {
    font-size: 9px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.14px;
}

.content-box1 {
    width: 360px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.content-box2 {
    width: 126px;
    display: flex;
    flex-direction: column;
}