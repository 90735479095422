.pageLayout {
  width: 100%;
  display: grid;
  gap: 24px;
  padding-block: 2rem;
  padding: 3rem 1rem;

  .pageHeader {
    display: flex;
    min-width: 320px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .pageTitle {
    color: #3468c5;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;
    line-height: normal;
  }
  .pageSubTitle {
    color: #475467;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .pageWrapper {
    display: grid;
    grid-template-rows: 3fr 1fr auto;
    gap: 14px;
  }

  .Content,
  .Top_Section {
    display: grid;
    grid-template-columns: 7fr 3fr;
    gap: 24px;
  }
  .Side_Section {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.Top_Details_Box {
  display: flex;
  width: 1144px;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  .Right_Side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .idRef {
    color: #004eeb;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .TypeWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .Type {
    color: #272963;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  .UnitNumber {
    color: #004eeb;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  .UnitNameWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .UnitName {
    color: #101828;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
  .Capsules_container {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 8px;
  }
  .Outlined_capsule__gray {
    display: flex;
    padding: 2px 8px;
    align-items: center;
    border-radius: 9999px;
    border: 1px solid #eaecf0;
    background: #f9fafb;
  }
  .Left_Side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  .labelPrice {
    opacity: 0.75;
    color: #3b72d9;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
  }
  .unitPrice {
    color: #272963;
    font-size: 32px;
    font-weight: 600;
    line-height: 30px;
  }
}
