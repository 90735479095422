.TransactionSummary {
  .summary_banner {
    display: flex;
    padding: 32px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background: #f9fafb;

    .banner_logo {
      img {
        width: 75px;
        height: 65px;
      }
    }

    .banner_content {
      display: flex;
      align-items: center;

      h2 {
        font-size: 16px;
        font-weight: bold;
        margin: 0 0 5px;
      }

      p {
        font-size: 14px;
        color: #6c757d;
        margin: 0;
      }
    }

    .banner_date {
      display: flex;
      font-size: 12px;
      margin: 4px;
      font-weight: 600;
      padding: 4px 12px;
      align-items: center;
      border-radius: 9999px;
      border: 1px solid #cabfac;
      background: #f4f1ed;
      color: #8c6d48;
    }
  }

  .summary-cards-wrapper {

    .summary-card {
      display: flex;
      width: 260px;
      height: 100px;
      padding: 12px;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      border-radius: 6px;
      border: 1px solid #EDEDED;
      background-color: #fff;
      flex-grow: 1;
    }
  }

  .hover-effect {
    .action-button {
      display: none;
    }

    &:hover {
      .hover-container {
        transition: all .4s;
        border-radius: 8px;
        background-color: #87878714;
        padding-top: 24px !important;
      }

      .action-button {
        transition: all .4s;
        display: initial;
        min-height: 34px;
        .action-icon {
          width: 24px;
          height: 24px;
          padding-inline: 4px;
          opacity: .9;
        }
      }

      .summary-card {
        background: #f9fafb;
      }
    }
  }
}