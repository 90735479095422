.unit-details-dialog-wrapper {
  .form-item {
    width: 100%;
    margin-top: 1rem;

    .border-head {
      padding: 16px;
      border: 1px solid #ccc;
      border-radius: 8px;
      background-color: #f9f9f9;

      .line {
        height: 1px;
        background-color: #ccc;
        margin: 16px 0;
      }

      .section-1,
      .section-2 {
        margin-bottom: 16px;

        .action-box {
          display: flex;
          flex-direction: column;

          .checkboxes-component {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            label {
              margin-left: 8px;
              font-size: 16px;
              color: #333;
            }
          }
        }
      }

      .section-2 {
        .form-item {
          margin-top: 1rem;

          &.w-100 {
            width: 100%;
          }

          &.mt-3 {
            margin-top: 3rem;
          }

          >* {
            margin-bottom: 1.5rem;
          }

          .period-input {
            margin-top: 1rem;
            width: 200%;
          }


          .switch-component {
            display: flex;
            align-items: center;
            margin-top: 1rem;
            gap: 12rem;
            label {
              margin-left: 8px;
              font-size: 16px;
              color: #333;
            }

          }
        }
      }
    }
  }
}