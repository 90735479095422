.buttons_container{
    width: fit-content;
    justify-content: center;
    align-content: center;
    display: flex;
}

.MarketingAndAgentContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
}


.publish-dialog-data-and-inputs-container{
    display: flex;
    flex-direction: column;
    padding: 4px 20px;
}