.filter-slider-container {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styled-slider-fieldset {
  border: 1px solid gainsboro;
  padding: 10px;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-wrapper {
  width: 100%;
  margin: 10px 0;
}

.inputs-wrapper-slider {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-field-slider {
  width: 100px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.range-separator {
  font-weight: bold;
  font-size: 22px;
  height: 21px;
}
