.unit-approvals-dialog-wrapper {
  .w-100 {
    width: 100%;
  }

  .mt-1 {
    margin-top: 1rem;
  }



  .main-page-title {
    display: flex;
    align-items: center;

    .main-page-title-text-1 {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .line {
    border-bottom: 1px solid #ddd;
    margin: 1rem 0;
  }

  .form-item {
    width: 25%;
    margin-top: 1rem;

    &.w-100 {
      width: 100%;
    }

    &.mt-3 {
      margin-top: 3rem;
    }

    > * {
      margin-bottom: 1.5rem;
    }
  }

  .period-input {
    margin-top: 1rem;
    width: 200%;
  }

  .checkbox-container {
    display: flex;
    margin-top: 1rem;
    gap: 12rem;
  }


  .section-1,
  .section-2 {
    .action-box {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.publish-unit-approvals-wrapper{
  .header-section{
    padding: 0.5rem 0.5rem 1rem !important;
    margin-bottom: 1rem;
  }
  .unit-approval-fields{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .table-capsule{
    padding: 4px 12px;
    border-radius: 9999px;
    border: 1px solid #EAECF0;
    background:  #F9FAFB;
  }
}
