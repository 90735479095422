.PickerItems_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: fit-content;
  gap: 12px;

  .PickerItems_Drag_item {
    position: relative;
    transition: transform 0.3s ease;
    cursor: pointer;

    // &:hover {
    //   transform: scale(1.015);
    // }
  }

  .Drag_item_viewMore {
    &:hover {
      transform: scale(1);
    }

    &::before {
      content: var(--additional-items-count);
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(4px);
      -webkit-backdrop-filter: blur(4px);
      z-index: 4;
      width: 120px;
      height: 120px;
      border-radius: 8px;
      color: white;
      font-size: 20px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  fieldset {
    position: absolute;
    top: 0;
    left: 0;

    .MuiIconButton-label {
      background: white;
      border-radius: 6px;
    }
  }

}