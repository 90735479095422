.contact-source-card-container {
  background-color: white;
  border: solid 2px #f5f5f5;
  border-radius: 0.5rem;
  padding: 8px;
  max-width: 500px;
  margin: 0 auto;

  .contact-source-card-upper-section {
    text-align: center;

    .contact-source-card-title {
      color: #007aff;
    }
    .contact-source-card-upper-section-item-container {
        display: flex;
        flex-direction: row;
        gap: 4px;
        width: fit-content;
        margin: 6px auto;

      .contact-source-card-upper-section-item {
        background: #007aff;
        border-radius: 0.5rem;
        color: white;
        width: fit-content;
        padding: 4px 8px;
      }
    }
  }

  .contact-source-card-bottom-section {
    padding: 4px;
    background: #f9f9fd;
    border-radius: 0.5rem;
    margin-top: 8px;
  }
}
