.campaign-team-f1 {

    .add-team-btn {
        min-width: 3rem;
    }

    .info-container {
        display: flex;
    }
    .team-avatar{
        height: 38px;
        width: 38px;
        max-width: 38px;
        min-width: 38px;
        margin-right: .8rem;
        font-size: 1rem;
        img {
            object-fit: fill;
        }
        &.sm-theme{
            height: 32px;
            width: 32px;
            max-width: 32px;
            min-width: 32px;
        }
        &.xs-theme{
            height: 28px;
            width: 28px;
            max-width: 28px;
            min-width: 28px;
        }
    }

    .req-avatar {
        height: 42px;
        width: 42px;
        max-width: 42px;
        min-width: 42px;
        margin-right: .8rem;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        border: 1px solid #f3f3f3;
        &.sm-theme{
            height: 32px;
            width: 32px;
            max-width: 32px;
            min-width: 32px;
        }
    }

    .team-remove-btn::before {
        font-size: 15px;
        color: #d16a6a;
        transform: rotate(135deg);
        cursor: pointer;
    }
    .team-member-field {
        min-width: unset;
    }
    .team-member-record{
        width: 88%;
        border-bottom: whitesmoke 1px solid;
        &:hover{
            background: aliceblue;
            border-radius: 5px;
        }
    }
    .role-capsule {
        font-weight: 700;
        font-size: 1em;
        margin-bottom: 0;
        background: #f2f9ff;
        padding: 0.2rem 0.8rem;
        border-radius: 5px;
        color: var(--c-primary);
        width: max-content;
        margin-right: 1rem;
    }

    .top-label {
        flex: 0 0 fit-content;
        align-items: flex-start;

        :first-child {
            font-size: 12px;
            font-weight: 600;
            margin: 0px;
        }

        :last-child {
            font-size: 10px;
            margin: 0px;
        }
    }

}
