.kenban-cards-layout {
  width: fit-content;
  // width: 100%;
  // margin: auto;
  overflow-x: auto;

  .kenban-cards-container {
    width: fit-content;
    // margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 281px);
    grid-template-rows: 65vh;
    column-gap: 1rem;
  }

  .kenban-col-header {
    max-height: 60px;
    &.bg-col-gray {
      background: #f4f5f7;
    }
    &.bg-col-blue {
      background: #f0f8ff;
    }
    &.bg-col-yellow {
      background: #fffbf3;
    }
    &.bg-col-green {
      background: #f8fff3;
    }
    .col-count {
      background-color: white;
    }
  }
  .kenban-column {
    height: 100%;
    min-width: 240px;
    max-width: 281px;
    background-color: #f9f9f9;
  }

  .kenban-cols {
    overflow: auto;
    height: 70vh;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  }
  .kenban-cols-headrs, .kenban-cols {
    width: fit-content;
    // margin: auto;
    display: grid;
    grid-template-columns: repeat(4, 281px);
    column-gap: 1rem;
  }
}
